@import '~antd/es/style/themes/default.less';
@import '~antd/es/style/mixins/index.less';

@fullscreen-header-height-lg-screen: 94px;
@fullscreen-header-height-sm-screen: 64px;

// OCIO sliver
#iowa_sliver {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

// Layout
.app-spinner {
  .ant-spin {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 29px);
  }

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 800;
  background-color: #ffffff;
}

#root {
  height: calc(100vh - 41px); // OCIO sliver is 41px tall
  > .ant-layout {
    min-height: calc(100vh - 41px); // OCIO sliver is 41px tall
  }
}

.ant-layout.ant-layout-has-sider > .ant-layout, 
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: unset;
}

.ant-layout > .ant-layout.page-content, 
.ant-layout > .ant-layout-content.page-content,
.ant-layout.ant-layout-has-sider > .ant-layout.page-content, 
.ant-layout.ant-layout-has-sider > .ant-layout-content.page-content {
  position: absolute;
  top: calc(40px + @fullscreen-header-height-lg-screen);
  transition: all 0.2s;
  width: 100%;
  // padding: 30px;

  @media (max-width: 991px) {
    top: calc(40px + @fullscreen-header-height-sm-screen);
    // padding: 20px;
  }

  > .ant-layout-content {
    min-height: calc(100vh - @fullscreen-header-height-lg-screen - 40px - 70px);

    @media (max-width: 991px) {
      min-height: calc(100vh - @fullscreen-header-height-sm-screen - 40px - 70px);
    }
  }
}

.ant-layout.ant-layout-has-sider > .ant-layout.page-content, 
.ant-layout.ant-layout-has-sider > .ant-layout-content.page-content {
  position: absolute;
  left: 248px;
  width: calc(100% - 248px);
  // padding: 30px;

  @media (max-width: 991px) {
    // padding: 20px;
    position: absolute;
    left: 0;
    width: 100%;
  }
}

.ant-layout.ant-layout-has-sider > .ant-layout.page-content.page-content.sider-collapsed, 
.ant-layout.ant-layout-has-sider > .ant-layout-content.page-content.page-content.sider-collapsed {
  left: 0;
  width: 100%;
}

.content-pad {
  padding: 32px;


  @media (max-width: 767px) {
    padding: 20px;
  }
}

.total {
    font-weight: bold;
    font-size: 20px;


    @media (max-width: 767px) {
        padding: 20px;
    }
}

.ant-layout > .ant-layout.page-content.public, 
.ant-layout > .ant-layout-content.page-content.public {
  .public-content {
    max-width: @screen-xxl;
    margin: auto;
  }
}

.main-container {
  position: relative;
  top: @fullscreen-header-height-lg-screen;

  @media (max-width: 991px) {
    top: @fullscreen-header-height-sm-screen;
    // padding: 20px;
  }
}

@media (max-width: 768px) {
    .column-divider {
        display: none;    
    }
}

// Header

.ant-layout-header {
    position: fixed;
    z-index: 900;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid @shadow-color;
    height: @fullscreen-header-height-lg-screen !important;
    transition: all 0.2s;

    .ant-menu-horizontal,
    .ant-menu-horizontal:not(.ant-menu-dark) {
        padding-left: 30px;
        display: flex;
        align-items: center;
        border-bottom: none !important;
        justify-content: flex-end;

        .ant-menu-item,
        .ant-menu-item-active,
        .ant-menu-item-selected,
        .ant-menu-item:hover,
        .ant-menu-submenu,
        .ant-menu-submenu:hover {
            border-bottom: none !important;
        }

        .ant-menu-item .ant-btn-link,
        .ant-menu-submenu-title .ant-btn-link {
            display: flex;
            align-items: center;
        }

        .ant-menu-item .ant-btn-link:active,
        .ant-menu-submenu-title .ant-btn-link:active,
        .ant-menu-item .ant-btn-link:hover,
        .ant-menu-submenu-title .ant-btn-link:hover,
        .ant-menu-item .ant-btn-link:focus,
        .ant-menu-submenu-title .ant-btn-link:focus {
            color: #111111;
        }

        .ant-menu-item .ant-menu-item-icon,
        .ant-menu-submenu-title .ant-menu-item-icon,
        .ant-menu-item .anticon,
        .ant-menu-submenu-title .anticon {
            font-size: 24px;
        }
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
        margin: 0;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
        display: none;
    }

    .anticon-menu.trigger {
        font-size: 28px;
    }

    @media (max-width: 991px) {
        .ant-menu-horizontal,
        .ant-menu-horizontal:not(.ant-menu-dark) {
            width: 310px;
            padding-left: 10px;
        }

        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
            padding: 10px;
        }

        .ant-menu-item {
            display: flex !important;

            .ant-btn-link {
                height: 38px;

                > .anticon {
                    font-size: 28px;

                    + span {
                        display: none;
                    }
                }
            }
        }

        .anticon.anticon-down {
            display: none;
        }
    }

    @media (max-width: 767px) {
        .ant-menu-horizontal,
        .ant-menu-horizontal:not(.ant-menu-dark) {
            padding-left: 5px;
        }

        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
            padding: 5px;
        }
    }

    @media (min-width: 992px) {
        .anticon-menu.trigger {
            display: none;
        }
    }
}

@media (max-width: 991px) {
    .ant-layout-header {
        height: @fullscreen-header-height-sm-screen !important;
    }
}

.header-logo {
    display: flex;
    align-items: center;

    img {
        height: 64px;

        &.header-logo-icon {
            display: block;
        }
    }

    .header-icon-mobile {
        display: none;
    }

    .header-logo-text {
        margin-bottom: 0;
        margin-left: 15px;
    }

    @media (max-width: 1340px) {
        .header-logo-text {
            display: none;
            margin-top: 0px;
        }
    }

    @media (max-width: 1339px) {
        img {
            height: 48px;

            &.header-logo-icon {
                display: none;
            }
        }

        .header-icon-mobile {
            display: inline-block;
        }
    }
}

// Sidebar

.scrollbar-sidenav {
    position: absolute !important;
    top: @fullscreen-header-height-lg-screen !important;
    bottom: 0 !important;
    height: auto !important;
    border-right: 1px solid #E6E6E6;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fffaf7 !important;
}

.ant-layout-sider {
    position: fixed !important;
    left: 0;
    top: 40px;
    // top: 0;
    // height: calc(100vh - 94px - 35px);
    height: calc(100% - 40px);

    .ant-menu.sider-menu {
        padding-bottom: 30px;
        background-color: white;
    }

    .ant-menu {
        border-right: none;
        width: 247px;

        .ant-menu-item {
            height: 22px !important;
            line-height: 22px !important;

            a {
                text-decoration: none;
                font-weight: bold;
            }
        }

        .ant-menu-inline .ant-menu-item {
            margin-bottom: 0;
            margin-top: 0;
        }

        .menu-item-standalone {
            padding-left: 48px !important;
            margin-top: 20px;
            font-size: 16px;

            a {
                font-weight: normal;
            }
        }

        .menu-item-standalone.danger a {
            color: @error-color;
        }

        .menu-item-standalone.bold a {
            font-weight: bold;
        }

        .ant-menu-submenu {

            .ant-menu-submenu-title {
                color: #757575;
                font-weight: 400;
                font-size: 16px;
            }
        }

        .ant-menu-submenu-title {
            margin-top: 0;
            margin-bottom: 0;
        }

        .ant-menu-item .ant-menu-item-icon,
        .ant-menu-submenu-title .ant-menu-item-icon,
        .ant-menu-item .anticon,
        .ant-menu-submenu-title .anticon {
            font-size: 16px;
            margin-right: 8px;
        }
    }

    .committee-header {
        background-color: white;
        padding-left: 24px;
        padding-top: 5px;
        padding-bottom: 4px;

        @media (max-width: 767px) {
            margin-right: 17px;
        }

        .committee-label {
            font-size: 14px;
            color: #757575;
            font-weight: 400;
            display: block;
        }

        .committee {
            font-size: 14px;
            font-weight: bold;
            display: block;
        }
    }
}

@media (max-width: 991px) {
    .ant-layout-sider {
        position: fixed !important;
        z-index: 1;
        // height: calc(100vh - 35px);
        height: unset;
        bottom: 0;

        .scrollbar-sidenav {
            top: @fullscreen-header-height-sm-screen !important;
        }
    }
}
// Buttons

.ant-btn:not(.ant-btn-link) {
    &:hover,
    &:focus {
        color: @btn-default-color;
        border-color: @btn-default-border;
    }

    &.ant-btn-primary {
        &:hover,
        &:focus {
            border-color: @primary-color;
        }
    }
}

.ant-btn-link {
    color: @primary-color;

    .anticon.anticon-down {
        margin-right: 0;
        font-size: 10px;
        vertical-align: baseline;
    }
}

.table-row-button-group {
    display: flex;
    justify-content: center;

    button:not(:last-of-type) {
        margin-right: 5px;
    }

    .icon-button {
        font-size: 20px;
    }
}

.ant-btn-link span {
    color: @heading-color;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
}
// Popovers

.ant-popover.header-contact-content,
.ant-popover.header-account-content {
    h5 {
        margin: 0;
    }

    .ant-col {
        padding: 25px 0 25px 10px;

        &:first-child {
            padding-left: 30px;
        }

        &:last-child {
            padding-right: 20px;
        }
    }

    .ant-popover-inner-content {
        padding: 0;
    }

    .ant-popover-inner {
        max-width: 660px;
    }

    .ant-menu {
        border: none;

        li {
            margin: 0;
            padding: 0;
            height: auto;
            line-height: 1.4;

            a {
                padding: 4px 15px;
                display: block;
            }

            &:first-child {
                a {
                    padding-top: 15px;
                }
            }

            &:last-child {
                padding: 4px 15px 15px;

                a {
                    padding-bottom: 0;
                }
            }
        }
    }
}

.ant-popover.header-account-content {
    .ant-popover-inner {
        min-width: 180px;
    }
}

@media (max-width: 767px) {
    .ant-popover.header-contact-content,
    .ant-popover.header-account-content {
        padding: 0 !important;
        top: 64px !important;
        width: 100% !important;
        text-align: right;

        h5 {
            font-weight: bold;
        }

        .ant-typography {
            font-size: 22px;
        }

        .ant-col {
            padding: 20px 30px 0;

            &:last-child {
                padding-bottom: 20px;
            }
        }

        .ant-menu li a {
            padding: 10px 30px 0;
        }

        .ant-menu li:first-child a {
            padding-top: 20px;
        }

        .ant-menu li:last-child {
            padding: 10px 30px 20px;
        }

        .ant-popover-inner {
            max-width: none;
        }

        .ant-menu {
            text-align: right;
        }

        .ant-popover-arrow {
            display: none !important;
        }
    }
}
// Spacing

.ant-space-vertical {
    .ant-space-item {
        margin-bottom: 4px !important;
    }
}

.full-logo {
    margin-bottom: 24px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    .ant-typography {
        display: inline-block;
        margin-left: 15px;
    }
}

.report {
    height: 69vh;
    margin: 1% auto;
    width: 60%;
}
// Forms

.ant-form {
    max-width: 1140px;

    .ant-form-item {
        margin-bottom: 20px;

        .ant-form-item-label,
        .ant-checkbox-wrapper {
            font-weight: bold;
        }

        .ant-form-item-explain,
        .ant-form-item-extra {
            min-height: 20px;
            line-height: 20px;
        }
    }

    .ant-form-item.hide-input {
        .ant-form-item-control-input {
            display: none;
        }
    }

    .ant-form-item.ant-form-item-with-help {
        margin-bottom: 0;
    }
}

.ant-form.center {
    margin-left: auto !important;
    margin-right: auto !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
    content: none !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.ant-form.ant-form-horizontal.form-flex {
    .ant-row.ant-form-item:not(.ant-form-item-hidden),
    > .ant-row > .ant-col > .ant-row.ant-form-item:not(.ant-form-item-hidden) {
        display: flex;
        flex-direction: row;

        > .ant-form-item-label {
            margin-right: 5px;
        }

        > .ant-form-item-control-wrapper {
            flex: 1;
            margin-left: 5px;
        }
    }
}

.ant-form.ant-form-inline.form-flex {
    display: flex;

    > .ant-row.ant-form-item {
        display: flex;
        flex: 1;

        > .ant-form-item-label {
            > label {
                padding-left: 10px;
            }
        }

        > .ant-form-item-control-wrapper {
            flex: 1;
            margin-left: 5px;
        }
    }
}

.ant-picker {
    width: 100%;
}

.labelSize-variation(@sizes, @index) when (@index > 0) {
    .labelSize-variation(@sizes; (@index - 1)); // decrement.

    @size: extract(@sizes, @index);

    .ant-form.label-@{size} {
        .ant-form-item,
        > .ant-row > .ant-col > .ant-form-item {
            > .ant-form-item-label {
                width: @size*1px;
            }
        }
    }
}

@labelSizes: 80, 100, 120, 140, 160;
.labelSize-variation(@labelSizes; length(@labelSizes));

.formSize-variation(@sizes, @index) when (@index > 0) {
    .formSize-variation(@sizes; (@index - 1)); // decrement.

    @size: extract(@sizes, @index);

    .ant-form.max-@{size} {
        max-width: @size*1px;
    }
}

@maxFormSizes: 400, 800, 1000, 1200, 1600;
.formSize-variation(@maxFormSizes; length(@maxFormSizes));

.ant-form-inline .ant-form-item {
    margin-right: 0;
}

.ant-form-item.flex-row > .ant-form-item-control-wrapper {
    flex: 1;
}

.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children,
.ant-form-inline .ant-form-item.flex-row,
.flex-row {
    display: flex;
    flex-direction: row;
}

.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-control,
.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > .flex,
.flex-row > .flex {
    flex: 1;
}

.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > *,
.flex-row > * {
    margin: 0 5px;
}

.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > *:first-child,
.flex-row > *:first-child {
    margin-left: 0;
}

.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > *:last-child,
.flex-row > *:last-child {
    margin-right: 0;
}

.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > *,
.flex-row > .ant-btn.ant-btn-sm {
    margin: 0 2px;
}

.flex-wrap {
    flex-wrap: wrap;
}
// MISC

.clickable {
    cursor: pointer;
}

.review-affirmation {
    font-weight: bold;
}

.indent {
    margin-left: 1em;
}

.ant-input-number {
    width: 100% !important;
}

.ant-notification.ant-notification-topRight {
    z-index: 11000;
}

.ant-table-container {
    overflow-x: auto;
}
    
.ant-descriptions-item-content {
    word-break: normal !important;
    overflow-wrap: normal !important;
}

.ant-descriptions-title {
    white-space: unset !important;
}

.ant-typography.bold-heading {
    font-weight: 900;
}

// Input Arrows

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.ant-input-number-handler-wrap {
    display: none;
}

// Modal
.ant-modal-wrap {
    z-index: 1000;
}

.ant-dropdown {
    z-index: 800 !important;
}

.datatable.datatable-alternating-row .ant-table tr:nth-child(even) {
    background: #ebebeb !important;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td {
    border-right: 1px solid #9E9E9A !important;
    border-bottom: 1px solid #9e9e9e;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th {
    border-top: 1px solid #9E9E9A;
    border-right: 1px solid #9E9E9A !important;
    border-bottom: 2px solid #9E9E9A !important;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:first-child th:first-child,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:first-child th:first-child {
    border-left: 1px solid #9E9E9A;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:first-child,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td:first-child {
    border-left: 1px solid #9E9E9A;
}

.dr2-status-items {
    font-weight: bold !important;
    padding-right: 15px;
}

.data-table-link {
    overflow-wrap: break-word;
    text-align: center;
    width: 100%;
    white-space: normal;
}

.data-table-link > span {
    color: #073394;
    text-decoration: underline;
}

.data-table-link > span:hover {
    color: #D03805;
}

.public-report-links {
    font-weight: bold;
    padding-left: 0;
    color: #111111;
    text-decoration: none;
    
    :hover {
        color: #D03805;
    }
}

.public-report-description-links {
    color: #D03805;
}

.center-spinner {
    text-align: center;
}

.note-button {
    margin-left: 5px;
    padding: 0px 15px;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.bold-checkbox {
    font-weight: bold;

    .ant-checkbox-inner {
        border-color: #111111;
    }
}

.option-menu-button {
    background-color: #2F5E8C;
    color: white;
    font-weight: bold;
    border-color: #2F5E8C;
    padding: 3px;
    margin-top: -3px;
    margin-bottom: -3px;
    margin-left: -2px;
    :hover {
    }
}


.ant-select-selection-placeholder {
    color: #757575 !important;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
    color: #757575 !important;
}

.ant-btn-dangerous {
    color: #EB0004 !important;
}
@primary-color: #D03805;@normal-color: #073394;@heading-color: fade(#000, 90%);@text-color: #111111;@text-color-secondary: fade(#000, 60%);@heading-color-dark: fade(#fff, 90%);@text-color-dark: fade(#fff, 80%);@text-color-secondary-dark: fade(#fff, 60%);@link-hover-color: @primary-color;@link-active-color: @primary-color;@link-decoration: underline;@font-family: Arial, sans-serif;@code-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;@border-radius-base: 2px;@breadcrumb-base-color: #000;@layout-body-background: #fff;@layout-header-background: #fff;@layout-header-padding: 0 20px;@layout-sider-background: #fff;@layout-trigger-background: transparent;@layout-trigger-color: fade(#000, 80%);@menu-bg: transparent;@menu-item-height: 26px;@menu-inline-toplevel-item-height: 26px;@menu-item-font-size: 14px;@menu-item-color: @normal-color;@menu-item-group-title-color: #757575;@menu-item-group-title-font-size: 16px;@menu-inline-submenu-bg: @menu-bg;@divider-color: rgba(0, 0, 0, 10%);@disabled-color: @text-color;