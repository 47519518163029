@import '~antd/es/style/themes/default.less';
@import '~antd/es/style/mixins/index.less';

.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  font-size: 18px;
}

.text-filter-dropdown,
.date-filter-dropdown,
.daterange-filter-dropdown,
.boolean-radio-filter-dropdown,
.boolean-checkbox-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  display: flex;
}

.text-filter-dropdown input {
  width: 130px;
  margin-right: 8px;
}

.date-filter-dropdown .ant-calendar-picker-input {
  width: 278px;
  margin-right: 8px;
}

.daterange-filter-dropdown .ant-calendar-picker-input {
  width: 550px;
  margin-right: 8px;
}

.ant-table-wrapper table {
  background-color: #fff;
}

.ant-table-small {
  border: none;

  > .ant-table-container > .ant-table-content > .ant-table-body {
    margin: 0;
  }
}

.ant-table-small tr > th:first-child {
  padding-left: 22px !important;
}

.ant-table-small tr > td:first-child {
  padding-left: 30px !important;
}

.ant-table-small tr > th:last-child {
  padding-right: 22px !important;
}

.ant-table-small tr > td:last-child {
  padding-right: 30px !important;
}

.datatable-header {
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: row;

  .ant-typography {
    flex: 1;
    margin-bottom: 10px;
    margin-right: 20px;
  }

  .generic-filter {
    margin-right: 20px;
    margin-bottom: 10px;
    max-width: 500px;
  }
}

.dataTable-button-bar {
  display: flex;
  margin-bottom: 10px;
}

.dataTable-button-bar .ant-btn:not(:last-child) {
  margin-right: 10px;
}

.datatable.datatable-bordered .ant-table {
  border: 1px solid #edf0f4;
}

.datatable .ant-table-tbody > tr > td {
  border-bottom: 1px solid #edf0f4;
}

.datatable td.editable-cell {
  padding: 2px 3px !important;
}

.datatable.datatable-compact .ant-table-filter-column-title {
  padding: 8px 2.3em 8px 8px;
}

.datatable.datatable-compact .ant-table-column-sorter {
  margin-left: 0;
}

.datatable.cursor-hand {
  tbody {
    tr {
      td {
        cursor: pointer;
      }
    }
  }
}

td.ant-table-column-sort {
    background: rgba(0,0,0,0.04);
}

.ant-table-column-sorters .ant-table-column-title {
  margin-right: 9px;
}

.ant-table-column-title {
  z-index: auto;
}

.datatable .ant-table-column-sorters {
  width: unset;
  padding: 0;
}

.datatable .ant-table-filter-trigger-container {
  top: calc(50% + 4px);
  bottom: unset;
  right: -6px;
}

.datatable.datatable-alternating-row .ant-table {
  // .ant-table-thead > tr > th {
  //     background: rgba(0, 0, 0, 0.03) !important;
  // }

  .ant-table-thead > tr > th {
    border-top: 1px solid #edf0f4;
    border-bottom: 2px solid #edf0f4 !important;
  }

  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
    background: rgba(248, 248, 248, 1);
  }

  tr:nth-child(even) {
    background: #f7f9fc;
  }

  tr:nth-child(odd) {
    background: #fff;
  }
}

.datatable.datatable-compact .ant-table {
  font-size: 12px !important;

  .ant-table-thead > tr > th {
    padding: 6px !important;
  }

  .ant-table-thead > tr > th.ant-table-column-has-filters {
    padding-right: 30px !important;
  }

  .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
    margin-left: 0.25em;
  }

  .ant-input,
  .ant-input-number-input {
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.datatable.datatable-compact .ant-table.ant-table-small .ant-table-title,
.datatable.datatable-compact .ant-table.ant-table-small .ant-table-footer,
.datatable.datatable-compact .ant-table.ant-table-small .ant-table-thead > tr > th,
.datatable.datatable-compact .ant-table.ant-table-small .ant-table-tbody > tr > td,
.datatable.datatable-compact .ant-table.ant-table-small tfoot > tr > th,
.datatable.datatable-compact .ant-table.ant-table-small tfoot > tr > td {
  padding: 4px 4px;
}

.datatable.datatable-compact .ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
  padding-top: 4px;
  padding-left: 4px;
  padding-bottom: 4px;
}

.datatable.datatable-compact .ant-table-small tr > th:first-child,
.datatable.datatable-compact .ant-table-small tr > td:first-child {
  padding-left: 12px !important;
}

.datatable.datatable-compact .ant-table-small tr > th:last-child,
.datatable.datatable-compact .ant-table-small tr > td:last-child {
  padding-right: 12px !important;
}

i.link-button {
  // color: @normal-color;
  color: #0a70a6;
  font-size: 24px;
}

.dtSumRow > td {
  border-top: 2px solid #e8e8e8;
}

@primary-color: #D03805;@normal-color: #073394;@heading-color: fade(#000, 90%);@text-color: #111111;@text-color-secondary: fade(#000, 60%);@heading-color-dark: fade(#fff, 90%);@text-color-dark: fade(#fff, 80%);@text-color-secondary-dark: fade(#fff, 60%);@link-hover-color: @primary-color;@link-active-color: @primary-color;@link-decoration: underline;@font-family: Arial, sans-serif;@code-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;@border-radius-base: 2px;@breadcrumb-base-color: #000;@layout-body-background: #fff;@layout-header-background: #fff;@layout-header-padding: 0 20px;@layout-sider-background: #fff;@layout-trigger-background: transparent;@layout-trigger-color: fade(#000, 80%);@menu-bg: transparent;@menu-item-height: 26px;@menu-inline-toplevel-item-height: 26px;@menu-item-font-size: 14px;@menu-item-color: @normal-color;@menu-item-group-title-color: #757575;@menu-item-group-title-font-size: 16px;@menu-inline-submenu-bg: @menu-bg;@divider-color: rgba(0, 0, 0, 10%);@disabled-color: @text-color;