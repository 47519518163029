/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
#iowa_sliver {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}
.app-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 800;
  background-color: #ffffff;
}
.app-spinner .ant-spin {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 29px);
}
#root {
  height: calc(100vh - 41px);
}
#root > .ant-layout {
  min-height: calc(100vh - 41px);
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: unset;
}
.ant-layout > .ant-layout.page-content,
.ant-layout > .ant-layout-content.page-content,
.ant-layout.ant-layout-has-sider > .ant-layout.page-content,
.ant-layout.ant-layout-has-sider > .ant-layout-content.page-content {
  position: absolute;
  top: calc(40px + 94px);
  transition: all 0.2s;
  width: 100%;
}
@media (max-width: 991px) {
  .ant-layout > .ant-layout.page-content,
  .ant-layout > .ant-layout-content.page-content,
  .ant-layout.ant-layout-has-sider > .ant-layout.page-content,
  .ant-layout.ant-layout-has-sider > .ant-layout-content.page-content {
    top: calc(40px + 64px);
  }
}
.ant-layout > .ant-layout.page-content > .ant-layout-content,
.ant-layout > .ant-layout-content.page-content > .ant-layout-content,
.ant-layout.ant-layout-has-sider > .ant-layout.page-content > .ant-layout-content,
.ant-layout.ant-layout-has-sider > .ant-layout-content.page-content > .ant-layout-content {
  min-height: calc(100vh - 94px - 40px - 70px);
}
@media (max-width: 991px) {
  .ant-layout > .ant-layout.page-content > .ant-layout-content,
  .ant-layout > .ant-layout-content.page-content > .ant-layout-content,
  .ant-layout.ant-layout-has-sider > .ant-layout.page-content > .ant-layout-content,
  .ant-layout.ant-layout-has-sider > .ant-layout-content.page-content > .ant-layout-content {
    min-height: calc(100vh - 64px - 40px - 70px);
  }
}
.ant-layout.ant-layout-has-sider > .ant-layout.page-content,
.ant-layout.ant-layout-has-sider > .ant-layout-content.page-content {
  position: absolute;
  left: 248px;
  width: calc(100% - 248px);
}
@media (max-width: 991px) {
  .ant-layout.ant-layout-has-sider > .ant-layout.page-content,
  .ant-layout.ant-layout-has-sider > .ant-layout-content.page-content {
    position: absolute;
    left: 0;
    width: 100%;
  }
}
.ant-layout.ant-layout-has-sider > .ant-layout.page-content.page-content.sider-collapsed,
.ant-layout.ant-layout-has-sider > .ant-layout-content.page-content.page-content.sider-collapsed {
  left: 0;
  width: 100%;
}
.content-pad {
  padding: 32px;
}
@media (max-width: 767px) {
  .content-pad {
    padding: 20px;
  }
}
.total {
  font-weight: bold;
  font-size: 20px;
}
@media (max-width: 767px) {
  .total {
    padding: 20px;
  }
}
.ant-layout > .ant-layout.page-content.public .public-content,
.ant-layout > .ant-layout-content.page-content.public .public-content {
  max-width: 1600px;
  margin: auto;
}
.main-container {
  position: relative;
  top: 94px;
}
@media (max-width: 991px) {
  .main-container {
    top: 64px;
  }
}
@media (max-width: 768px) {
  .column-divider {
    display: none;
  }
}
.ant-layout-header {
  position: fixed;
  z-index: 900;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  height: 94px !important;
  transition: all 0.2s;
}
.ant-layout-header .ant-menu-horizontal,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) {
  padding-left: 30px;
  display: flex;
  align-items: center;
  border-bottom: none !important;
  justify-content: flex-end;
}
.ant-layout-header .ant-menu-horizontal .ant-menu-item,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item,
.ant-layout-header .ant-menu-horizontal .ant-menu-item-active,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item-active,
.ant-layout-header .ant-menu-horizontal .ant-menu-item-selected,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item-selected,
.ant-layout-header .ant-menu-horizontal .ant-menu-item:hover,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item:hover,
.ant-layout-header .ant-menu-horizontal .ant-menu-submenu,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu,
.ant-layout-header .ant-menu-horizontal .ant-menu-submenu:hover,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu:hover {
  border-bottom: none !important;
}
.ant-layout-header .ant-menu-horizontal .ant-menu-item .ant-btn-link,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item .ant-btn-link,
.ant-layout-header .ant-menu-horizontal .ant-menu-submenu-title .ant-btn-link,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu-title .ant-btn-link {
  display: flex;
  align-items: center;
}
.ant-layout-header .ant-menu-horizontal .ant-menu-item .ant-btn-link:active,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item .ant-btn-link:active,
.ant-layout-header .ant-menu-horizontal .ant-menu-submenu-title .ant-btn-link:active,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu-title .ant-btn-link:active,
.ant-layout-header .ant-menu-horizontal .ant-menu-item .ant-btn-link:hover,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item .ant-btn-link:hover,
.ant-layout-header .ant-menu-horizontal .ant-menu-submenu-title .ant-btn-link:hover,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu-title .ant-btn-link:hover,
.ant-layout-header .ant-menu-horizontal .ant-menu-item .ant-btn-link:focus,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item .ant-btn-link:focus,
.ant-layout-header .ant-menu-horizontal .ant-menu-submenu-title .ant-btn-link:focus,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu-title .ant-btn-link:focus {
  color: #111111;
}
.ant-layout-header .ant-menu-horizontal .ant-menu-item .ant-menu-item-icon,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item .ant-menu-item-icon,
.ant-layout-header .ant-menu-horizontal .ant-menu-submenu-title .ant-menu-item-icon,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu-title .ant-menu-item-icon,
.ant-layout-header .ant-menu-horizontal .ant-menu-item .anticon,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item .anticon,
.ant-layout-header .ant-menu-horizontal .ant-menu-submenu-title .anticon,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu-title .anticon {
  font-size: 24px;
}
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0;
}
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  display: none;
}
.ant-layout-header .anticon-menu.trigger {
  font-size: 28px;
}
@media (max-width: 991px) {
  .ant-layout-header .ant-menu-horizontal,
  .ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) {
    width: 310px;
    padding-left: 10px;
  }
  .ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    padding: 10px;
  }
  .ant-layout-header .ant-menu-item {
    display: flex !important;
  }
  .ant-layout-header .ant-menu-item .ant-btn-link {
    height: 38px;
  }
  .ant-layout-header .ant-menu-item .ant-btn-link > .anticon {
    font-size: 28px;
  }
  .ant-layout-header .ant-menu-item .ant-btn-link > .anticon + span {
    display: none;
  }
  .ant-layout-header .anticon.anticon-down {
    display: none;
  }
}
@media (max-width: 767px) {
  .ant-layout-header .ant-menu-horizontal,
  .ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) {
    padding-left: 5px;
  }
  .ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    padding: 5px;
  }
}
@media (min-width: 992px) {
  .ant-layout-header .anticon-menu.trigger {
    display: none;
  }
}
@media (max-width: 991px) {
  .ant-layout-header {
    height: 64px !important;
  }
}
.header-logo {
  display: flex;
  align-items: center;
}
.header-logo img {
  height: 64px;
}
.header-logo img.header-logo-icon {
  display: block;
}
.header-logo .header-icon-mobile {
  display: none;
}
.header-logo .header-logo-text {
  margin-bottom: 0;
  margin-left: 15px;
}
@media (max-width: 1340px) {
  .header-logo .header-logo-text {
    display: none;
    margin-top: 0px;
  }
}
@media (max-width: 1339px) {
  .header-logo img {
    height: 48px;
  }
  .header-logo img.header-logo-icon {
    display: none;
  }
  .header-logo .header-icon-mobile {
    display: inline-block;
  }
}
.scrollbar-sidenav {
  position: absolute !important;
  top: 94px !important;
  bottom: 0 !important;
  height: auto !important;
  border-right: 1px solid #E6E6E6;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fffaf7 !important;
}
.ant-layout-sider {
  position: fixed !important;
  left: 0;
  top: 40px;
  height: calc(100% - 40px);
}
.ant-layout-sider .ant-menu.sider-menu {
  padding-bottom: 30px;
  background-color: white;
}
.ant-layout-sider .ant-menu {
  border-right: none;
  width: 247px;
}
.ant-layout-sider .ant-menu .ant-menu-item {
  height: 22px !important;
  line-height: 22px !important;
}
.ant-layout-sider .ant-menu .ant-menu-item a {
  text-decoration: none;
  font-weight: bold;
}
.ant-layout-sider .ant-menu .ant-menu-inline .ant-menu-item {
  margin-bottom: 0;
  margin-top: 0;
}
.ant-layout-sider .ant-menu .menu-item-standalone {
  padding-left: 48px !important;
  margin-top: 20px;
  font-size: 16px;
}
.ant-layout-sider .ant-menu .menu-item-standalone a {
  font-weight: normal;
}
.ant-layout-sider .ant-menu .menu-item-standalone.danger a {
  color: #ff4d4f;
}
.ant-layout-sider .ant-menu .menu-item-standalone.bold a {
  font-weight: bold;
}
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
  color: #757575;
  font-weight: 400;
  font-size: 16px;
}
.ant-layout-sider .ant-menu .ant-menu-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
}
.ant-layout-sider .ant-menu .ant-menu-item .ant-menu-item-icon,
.ant-layout-sider .ant-menu .ant-menu-submenu-title .ant-menu-item-icon,
.ant-layout-sider .ant-menu .ant-menu-item .anticon,
.ant-layout-sider .ant-menu .ant-menu-submenu-title .anticon {
  font-size: 16px;
  margin-right: 8px;
}
.ant-layout-sider .committee-header {
  background-color: white;
  padding-left: 24px;
  padding-top: 5px;
  padding-bottom: 4px;
}
@media (max-width: 767px) {
  .ant-layout-sider .committee-header {
    margin-right: 17px;
  }
}
.ant-layout-sider .committee-header .committee-label {
  font-size: 14px;
  color: #757575;
  font-weight: 400;
  display: block;
}
.ant-layout-sider .committee-header .committee {
  font-size: 14px;
  font-weight: bold;
  display: block;
}
@media (max-width: 991px) {
  .ant-layout-sider {
    position: fixed !important;
    z-index: 1;
    height: unset;
    bottom: 0;
  }
  .ant-layout-sider .scrollbar-sidenav {
    top: 64px !important;
  }
}
.ant-btn:not(.ant-btn-link):hover,
.ant-btn:not(.ant-btn-link):focus {
  color: #111111;
  border-color: #d9d9d9;
}
.ant-btn:not(.ant-btn-link).ant-btn-primary:hover,
.ant-btn:not(.ant-btn-link).ant-btn-primary:focus {
  border-color: #D03805;
}
.ant-btn-link {
  color: #D03805;
}
.ant-btn-link .anticon.anticon-down {
  margin-right: 0;
  font-size: 10px;
  vertical-align: baseline;
}
.table-row-button-group {
  display: flex;
  justify-content: center;
}
.table-row-button-group button:not(:last-of-type) {
  margin-right: 5px;
}
.table-row-button-group .icon-button {
  font-size: 20px;
}
.ant-btn-link span {
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-popover.header-contact-content h5,
.ant-popover.header-account-content h5 {
  margin: 0;
}
.ant-popover.header-contact-content .ant-col,
.ant-popover.header-account-content .ant-col {
  padding: 25px 0 25px 10px;
}
.ant-popover.header-contact-content .ant-col:first-child,
.ant-popover.header-account-content .ant-col:first-child {
  padding-left: 30px;
}
.ant-popover.header-contact-content .ant-col:last-child,
.ant-popover.header-account-content .ant-col:last-child {
  padding-right: 20px;
}
.ant-popover.header-contact-content .ant-popover-inner-content,
.ant-popover.header-account-content .ant-popover-inner-content {
  padding: 0;
}
.ant-popover.header-contact-content .ant-popover-inner,
.ant-popover.header-account-content .ant-popover-inner {
  max-width: 660px;
}
.ant-popover.header-contact-content .ant-menu,
.ant-popover.header-account-content .ant-menu {
  border: none;
}
.ant-popover.header-contact-content .ant-menu li,
.ant-popover.header-account-content .ant-menu li {
  margin: 0;
  padding: 0;
  height: auto;
  line-height: 1.4;
}
.ant-popover.header-contact-content .ant-menu li a,
.ant-popover.header-account-content .ant-menu li a {
  padding: 4px 15px;
  display: block;
}
.ant-popover.header-contact-content .ant-menu li:first-child a,
.ant-popover.header-account-content .ant-menu li:first-child a {
  padding-top: 15px;
}
.ant-popover.header-contact-content .ant-menu li:last-child,
.ant-popover.header-account-content .ant-menu li:last-child {
  padding: 4px 15px 15px;
}
.ant-popover.header-contact-content .ant-menu li:last-child a,
.ant-popover.header-account-content .ant-menu li:last-child a {
  padding-bottom: 0;
}
.ant-popover.header-account-content .ant-popover-inner {
  min-width: 180px;
}
@media (max-width: 767px) {
  .ant-popover.header-contact-content,
  .ant-popover.header-account-content {
    padding: 0 !important;
    top: 64px !important;
    width: 100% !important;
    text-align: right;
  }
  .ant-popover.header-contact-content h5,
  .ant-popover.header-account-content h5 {
    font-weight: bold;
  }
  .ant-popover.header-contact-content .ant-typography,
  .ant-popover.header-account-content .ant-typography {
    font-size: 22px;
  }
  .ant-popover.header-contact-content .ant-col,
  .ant-popover.header-account-content .ant-col {
    padding: 20px 30px 0;
  }
  .ant-popover.header-contact-content .ant-col:last-child,
  .ant-popover.header-account-content .ant-col:last-child {
    padding-bottom: 20px;
  }
  .ant-popover.header-contact-content .ant-menu li a,
  .ant-popover.header-account-content .ant-menu li a {
    padding: 10px 30px 0;
  }
  .ant-popover.header-contact-content .ant-menu li:first-child a,
  .ant-popover.header-account-content .ant-menu li:first-child a {
    padding-top: 20px;
  }
  .ant-popover.header-contact-content .ant-menu li:last-child,
  .ant-popover.header-account-content .ant-menu li:last-child {
    padding: 10px 30px 20px;
  }
  .ant-popover.header-contact-content .ant-popover-inner,
  .ant-popover.header-account-content .ant-popover-inner {
    max-width: none;
  }
  .ant-popover.header-contact-content .ant-menu,
  .ant-popover.header-account-content .ant-menu {
    text-align: right;
  }
  .ant-popover.header-contact-content .ant-popover-arrow,
  .ant-popover.header-account-content .ant-popover-arrow {
    display: none !important;
  }
}
.ant-space-vertical .ant-space-item {
  margin-bottom: 4px !important;
}
.full-logo {
  margin-bottom: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.full-logo .ant-typography {
  display: inline-block;
  margin-left: 15px;
}
.report {
  height: 69vh;
  margin: 1% auto;
  width: 60%;
}
.ant-form {
  max-width: 1140px;
}
.ant-form .ant-form-item {
  margin-bottom: 20px;
}
.ant-form .ant-form-item .ant-form-item-label,
.ant-form .ant-form-item .ant-checkbox-wrapper {
  font-weight: bold;
}
.ant-form .ant-form-item .ant-form-item-explain,
.ant-form .ant-form-item .ant-form-item-extra {
  min-height: 20px;
  line-height: 20px;
}
.ant-form .ant-form-item.hide-input .ant-form-item-control-input {
  display: none;
}
.ant-form .ant-form-item.ant-form-item-with-help {
  margin-bottom: 0;
}
.ant-form.center {
  margin-left: auto !important;
  margin-right: auto !important;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
  content: none !important;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form.ant-form-horizontal.form-flex .ant-row.ant-form-item:not(.ant-form-item-hidden),
.ant-form.ant-form-horizontal.form-flex > .ant-row > .ant-col > .ant-row.ant-form-item:not(.ant-form-item-hidden) {
  display: flex;
  flex-direction: row;
}
.ant-form.ant-form-horizontal.form-flex .ant-row.ant-form-item:not(.ant-form-item-hidden) > .ant-form-item-label,
.ant-form.ant-form-horizontal.form-flex > .ant-row > .ant-col > .ant-row.ant-form-item:not(.ant-form-item-hidden) > .ant-form-item-label {
  margin-right: 5px;
}
.ant-form.ant-form-horizontal.form-flex .ant-row.ant-form-item:not(.ant-form-item-hidden) > .ant-form-item-control-wrapper,
.ant-form.ant-form-horizontal.form-flex > .ant-row > .ant-col > .ant-row.ant-form-item:not(.ant-form-item-hidden) > .ant-form-item-control-wrapper {
  flex: 1;
  margin-left: 5px;
}
.ant-form.ant-form-inline.form-flex {
  display: flex;
}
.ant-form.ant-form-inline.form-flex > .ant-row.ant-form-item {
  display: flex;
  flex: 1;
}
.ant-form.ant-form-inline.form-flex > .ant-row.ant-form-item > .ant-form-item-label > label {
  padding-left: 10px;
}
.ant-form.ant-form-inline.form-flex > .ant-row.ant-form-item > .ant-form-item-control-wrapper {
  flex: 1;
  margin-left: 5px;
}
.ant-picker {
  width: 100%;
}
.ant-form.label-80 .ant-form-item > .ant-form-item-label,
.ant-form.label-80 > .ant-row > .ant-col > .ant-form-item > .ant-form-item-label {
  width: 80px;
}
.ant-form.label-100 .ant-form-item > .ant-form-item-label,
.ant-form.label-100 > .ant-row > .ant-col > .ant-form-item > .ant-form-item-label {
  width: 100px;
}
.ant-form.label-120 .ant-form-item > .ant-form-item-label,
.ant-form.label-120 > .ant-row > .ant-col > .ant-form-item > .ant-form-item-label {
  width: 120px;
}
.ant-form.label-140 .ant-form-item > .ant-form-item-label,
.ant-form.label-140 > .ant-row > .ant-col > .ant-form-item > .ant-form-item-label {
  width: 140px;
}
.ant-form.label-160 .ant-form-item > .ant-form-item-label,
.ant-form.label-160 > .ant-row > .ant-col > .ant-form-item > .ant-form-item-label {
  width: 160px;
}
.ant-form.max-400 {
  max-width: 400px;
}
.ant-form.max-800 {
  max-width: 800px;
}
.ant-form.max-1000 {
  max-width: 1000px;
}
.ant-form.max-1200 {
  max-width: 1200px;
}
.ant-form.max-1600 {
  max-width: 1600px;
}
.ant-form-inline .ant-form-item {
  margin-right: 0;
}
.ant-form-item.flex-row > .ant-form-item-control-wrapper {
  flex: 1;
}
.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children,
.ant-form-inline .ant-form-item.flex-row,
.flex-row {
  display: flex;
  flex-direction: row;
}
.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-control,
.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > .flex,
.flex-row > .flex {
  flex: 1;
}
.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > *,
.flex-row > * {
  margin: 0 5px;
}
.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > *:first-child,
.flex-row > *:first-child {
  margin-left: 0;
}
.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > *:last-child,
.flex-row > *:last-child {
  margin-right: 0;
}
.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > *,
.flex-row > .ant-btn.ant-btn-sm {
  margin: 0 2px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.clickable {
  cursor: pointer;
}
.review-affirmation {
  font-weight: bold;
}
.indent {
  margin-left: 1em;
}
.ant-input-number {
  width: 100% !important;
}
.ant-notification.ant-notification-topRight {
  z-index: 11000;
}
.ant-table-container {
  overflow-x: auto;
}
.ant-descriptions-item-content {
  word-break: normal !important;
  overflow-wrap: normal !important;
}
.ant-descriptions-title {
  white-space: unset !important;
}
.ant-typography.bold-heading {
  font-weight: 900;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.ant-input-number-handler-wrap {
  display: none;
}
.ant-modal-wrap {
  z-index: 1000;
}
.ant-dropdown {
  z-index: 800 !important;
}
.datatable.datatable-alternating-row .ant-table tr:nth-child(even) {
  background: #ebebeb !important;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td {
  border-right: 1px solid #9E9E9A !important;
  border-bottom: 1px solid #9e9e9e;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th {
  border-top: 1px solid #9E9E9A;
  border-right: 1px solid #9E9E9A !important;
  border-bottom: 2px solid #9E9E9A !important;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:first-child th:first-child,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:first-child th:first-child {
  border-left: 1px solid #9E9E9A;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:first-child,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td:first-child {
  border-left: 1px solid #9E9E9A;
}
.dr2-status-items {
  font-weight: bold !important;
  padding-right: 15px;
}
.data-table-link {
  overflow-wrap: break-word;
  text-align: center;
  width: 100%;
  white-space: normal;
}
.data-table-link > span {
  color: #073394;
  text-decoration: underline;
}
.data-table-link > span:hover {
  color: #D03805;
}
.public-report-links {
  font-weight: bold;
  padding-left: 0;
  color: #111111;
  text-decoration: none;
}
.public-report-links :hover {
  color: #D03805;
}
.public-report-description-links {
  color: #D03805;
}
.center-spinner {
  text-align: center;
}
.note-button {
  margin-left: 5px;
  padding: 0px 15px;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.bold-checkbox {
  font-weight: bold;
}
.bold-checkbox .ant-checkbox-inner {
  border-color: #111111;
}
.option-menu-button {
  background-color: #2F5E8C;
  color: white;
  font-weight: bold;
  border-color: #2F5E8C;
  padding: 3px;
  margin-top: -3px;
  margin-bottom: -3px;
  margin-left: -2px;
}
.ant-select-selection-placeholder {
  color: #757575 !important;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #757575 !important;
}
.ant-btn-dangerous {
  color: #EB0004 !important;
}
